const PRICE_LIST_TSUMITATE_COURSE_A = {
  ID: "tsumitate-courseA",
  PRICE_M: [
    {
      AGE: 15,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 16,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 17,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 18,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 19,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 20,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 21,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 22,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 23,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 24,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 25,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 26,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 27,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 28,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 29,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 30,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 31,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 32,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 33,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 34,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 35,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 36,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 37,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 38,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 39,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 40,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 41,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 42,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 43,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 44,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 45,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 46,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 47,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 48,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 49,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 50,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 51,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 52,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 53,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 54,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 55,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 56,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 57,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 58,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 59,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 60,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 61,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 62,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 63,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 64,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 65,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 66,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 67,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 68,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 69,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 70,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 71,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 72,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 73,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 74,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 75,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
  ],
  PRICE_F: [
    {
      AGE: 15,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 16,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 17,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 18,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 19,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 20,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 21,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 22,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 23,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 24,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 25,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 26,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 27,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 28,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 29,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 30,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 31,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 32,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 33,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 34,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 35,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 36,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 37,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 38,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 39,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 40,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 41,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 42,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 43,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 44,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 45,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 46,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 47,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 48,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 49,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 50,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 51,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 52,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 53,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 54,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 55,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 56,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 57,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 58,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 59,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 60,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 61,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 62,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 63,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 64,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 65,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 66,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 67,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 68,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 69,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 70,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 71,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 72,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 73,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 74,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
    {
      AGE: 75,
      2: 2000,
      3: 3000,
      4: 4000,
      5: 5000,
      6: 6000,
      7: 7000,
      8: 8000,
      9: 9000,
      10: 10000,
    },
  ],
};
export { PRICE_LIST_TSUMITATE_COURSE_A };
