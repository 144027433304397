const PRICE_LIST_SEIKATSU_NURSING = {
  ID: "seikatsu-nursing",
  PRICE_M: [
    { AGE: 0, 1: 10, 2: 10, 3: 10 },
    { AGE: 1, 1: 10, 2: 10, 3: 10 },
    { AGE: 2, 1: 10, 2: 10, 3: 10 },
    { AGE: 3, 1: 10, 2: 10, 3: 10 },
    { AGE: 4, 1: 10, 2: 10, 3: 10 },
    { AGE: 5, 1: 10, 2: 10, 3: 10 },
    { AGE: 6, 1: 10, 2: 10, 3: 10 },
    { AGE: 7, 1: 10, 2: 10, 3: 10 },
    { AGE: 8, 1: 10, 2: 10, 3: 10 },
    { AGE: 9, 1: 10, 2: 10, 3: 10 },
    { AGE: 10, 1: 10, 2: 10, 3: 10 },
    { AGE: 11, 1: 10, 2: 10, 3: 10 },
    { AGE: 12, 1: 10, 2: 10, 3: 10 },
    { AGE: 13, 1: 10, 2: 10, 3: 10 },
    { AGE: 14, 1: 10, 2: 10, 3: 10 },
    { AGE: 15, 1: 10, 2: 10, 3: 10 },
    { AGE: 16, 1: 10, 2: 10, 3: 10 },
    { AGE: 17, 1: 10, 2: 10, 3: 10 },
    { AGE: 18, 1: 10, 2: 10, 3: 10 },
    { AGE: 19, 1: 10, 2: 10, 3: 10 },
    { AGE: 20, 1: 10, 2: 10, 3: 10 },
    { AGE: 21, 1: 10, 2: 10, 3: 10 },
    { AGE: 22, 1: 10, 2: 10, 3: 10 },
    { AGE: 23, 1: 10, 2: 10, 3: 10 },
    { AGE: 24, 1: 10, 2: 10, 3: 10 },
    { AGE: 25, 1: 10, 2: 10, 3: 20 },
    { AGE: 26, 1: 10, 2: 10, 3: 20 },
    { AGE: 27, 1: 10, 2: 10, 3: 20 },
    { AGE: 28, 1: 10, 2: 10, 3: 20 },
    { AGE: 29, 1: 10, 2: 10, 3: 20 },
    { AGE: 30, 1: 10, 2: 20, 3: 40 },
    { AGE: 31, 1: 10, 2: 20, 3: 40 },
    { AGE: 32, 1: 10, 2: 20, 3: 40 },
    { AGE: 33, 1: 10, 2: 20, 3: 40 },
    { AGE: 34, 1: 10, 2: 20, 3: 40 },
    { AGE: 35, 1: 20, 2: 50, 3: 70 },
    { AGE: 36, 1: 20, 2: 50, 3: 70 },
    { AGE: 37, 1: 20, 2: 50, 3: 70 },
    { AGE: 38, 1: 20, 2: 50, 3: 70 },
    { AGE: 39, 1: 20, 2: 50, 3: 70 },
    { AGE: 40, 1: 50, 2: 90, 3: 140 },
    { AGE: 41, 1: 50, 2: 90, 3: 140 },
    { AGE: 42, 1: 50, 2: 90, 3: 140 },
    { AGE: 43, 1: 50, 2: 90, 3: 140 },
    { AGE: 44, 1: 50, 2: 90, 3: 140 },
    { AGE: 45, 1: 60, 2: 110, 3: 170 },
    { AGE: 46, 1: 60, 2: 110, 3: 170 },
    { AGE: 47, 1: 60, 2: 110, 3: 170 },
    { AGE: 48, 1: 60, 2: 110, 3: 170 },
    { AGE: 49, 1: 60, 2: 110, 3: 170 },
    { AGE: 50, 1: 80, 2: 150, 3: 230 },
    { AGE: 51, 1: 80, 2: 150, 3: 230 },
    { AGE: 52, 1: 80, 2: 150, 3: 230 },
    { AGE: 53, 1: 80, 2: 150, 3: 230 },
    { AGE: 54, 1: 80, 2: 150, 3: 230 },
    { AGE: 55, 1: 110, 2: 220, 3: 330 },
    { AGE: 56, 1: 110, 2: 220, 3: 330 },
    { AGE: 57, 1: 110, 2: 220, 3: 330 },
    { AGE: 58, 1: 110, 2: 220, 3: 330 },
    { AGE: 59, 1: 110, 2: 220, 3: 330 },
    { AGE: 60, 1: 240, 2: 470, 3: 710 },
    { AGE: 61, 1: 240, 2: 470, 3: 710 },
    { AGE: 62, 1: 240, 2: 470, 3: 710 },
    { AGE: 63, 1: 240, 2: 470, 3: 710 },
    { AGE: 64, 1: 240, 2: 470, 3: 710 },
    { AGE: 65, 1: 490, 2: 980, 3: 1470 },
    { AGE: 66, 1: 490, 2: 980, 3: 1470 },
    { AGE: 67, 1: 490, 2: 980, 3: 1470 },
    { AGE: 68, 1: 490, 2: 980, 3: 1470 },
    { AGE: 69, 1: 490, 2: 980, 3: 1470 },
    { AGE: 70, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 71, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 72, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 73, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 74, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 75, 1: 2470, 2: 4940, 3: 7410 },
  ],
  PRICE_F: [
    { AGE: 0, 1: 10, 2: 10, 3: 10 },
    { AGE: 1, 1: 10, 2: 10, 3: 10 },
    { AGE: 2, 1: 10, 2: 10, 3: 10 },
    { AGE: 3, 1: 10, 2: 10, 3: 10 },
    { AGE: 4, 1: 10, 2: 10, 3: 10 },
    { AGE: 5, 1: 10, 2: 10, 3: 10 },
    { AGE: 6, 1: 10, 2: 10, 3: 10 },
    { AGE: 7, 1: 10, 2: 10, 3: 10 },
    { AGE: 8, 1: 10, 2: 10, 3: 10 },
    { AGE: 9, 1: 10, 2: 10, 3: 10 },
    { AGE: 10, 1: 10, 2: 10, 3: 10 },
    { AGE: 11, 1: 10, 2: 10, 3: 10 },
    { AGE: 12, 1: 10, 2: 10, 3: 10 },
    { AGE: 13, 1: 10, 2: 10, 3: 10 },
    { AGE: 14, 1: 10, 2: 10, 3: 10 },
    { AGE: 15, 1: 10, 2: 10, 3: 10 },
    { AGE: 16, 1: 10, 2: 10, 3: 10 },
    { AGE: 17, 1: 10, 2: 10, 3: 10 },
    { AGE: 18, 1: 10, 2: 10, 3: 10 },
    { AGE: 19, 1: 10, 2: 10, 3: 10 },
    { AGE: 20, 1: 10, 2: 10, 3: 10 },
    { AGE: 21, 1: 10, 2: 10, 3: 10 },
    { AGE: 22, 1: 10, 2: 10, 3: 10 },
    { AGE: 23, 1: 10, 2: 10, 3: 10 },
    { AGE: 24, 1: 10, 2: 10, 3: 10 },
    { AGE: 25, 1: 10, 2: 10, 3: 20 },
    { AGE: 26, 1: 10, 2: 10, 3: 20 },
    { AGE: 27, 1: 10, 2: 10, 3: 20 },
    { AGE: 28, 1: 10, 2: 10, 3: 20 },
    { AGE: 29, 1: 10, 2: 10, 3: 20 },
    { AGE: 30, 1: 10, 2: 20, 3: 40 },
    { AGE: 31, 1: 10, 2: 20, 3: 40 },
    { AGE: 32, 1: 10, 2: 20, 3: 40 },
    { AGE: 33, 1: 10, 2: 20, 3: 40 },
    { AGE: 34, 1: 10, 2: 20, 3: 40 },
    { AGE: 35, 1: 20, 2: 50, 3: 70 },
    { AGE: 36, 1: 20, 2: 50, 3: 70 },
    { AGE: 37, 1: 20, 2: 50, 3: 70 },
    { AGE: 38, 1: 20, 2: 50, 3: 70 },
    { AGE: 39, 1: 20, 2: 50, 3: 70 },
    { AGE: 40, 1: 50, 2: 90, 3: 140 },
    { AGE: 41, 1: 50, 2: 90, 3: 140 },
    { AGE: 42, 1: 50, 2: 90, 3: 140 },
    { AGE: 43, 1: 50, 2: 90, 3: 140 },
    { AGE: 44, 1: 50, 2: 90, 3: 140 },
    { AGE: 45, 1: 60, 2: 110, 3: 170 },
    { AGE: 46, 1: 60, 2: 110, 3: 170 },
    { AGE: 47, 1: 60, 2: 110, 3: 170 },
    { AGE: 48, 1: 60, 2: 110, 3: 170 },
    { AGE: 49, 1: 60, 2: 110, 3: 170 },
    { AGE: 50, 1: 80, 2: 150, 3: 230 },
    { AGE: 51, 1: 80, 2: 150, 3: 230 },
    { AGE: 52, 1: 80, 2: 150, 3: 230 },
    { AGE: 53, 1: 80, 2: 150, 3: 230 },
    { AGE: 54, 1: 80, 2: 150, 3: 230 },
    { AGE: 55, 1: 110, 2: 220, 3: 330 },
    { AGE: 56, 1: 110, 2: 220, 3: 330 },
    { AGE: 57, 1: 110, 2: 220, 3: 330 },
    { AGE: 58, 1: 110, 2: 220, 3: 330 },
    { AGE: 59, 1: 110, 2: 220, 3: 330 },
    { AGE: 60, 1: 240, 2: 470, 3: 710 },
    { AGE: 61, 1: 240, 2: 470, 3: 710 },
    { AGE: 62, 1: 240, 2: 470, 3: 710 },
    { AGE: 63, 1: 240, 2: 470, 3: 710 },
    { AGE: 64, 1: 240, 2: 470, 3: 710 },
    { AGE: 65, 1: 490, 2: 980, 3: 1470 },
    { AGE: 66, 1: 490, 2: 980, 3: 1470 },
    { AGE: 67, 1: 490, 2: 980, 3: 1470 },
    { AGE: 68, 1: 490, 2: 980, 3: 1470 },
    { AGE: 69, 1: 490, 2: 980, 3: 1470 },
    { AGE: 70, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 71, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 72, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 73, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 74, 1: 1080, 2: 2150, 3: 3230 },
    { AGE: 75, 1: 2470, 2: 4940, 3: 7410 },
  ],
};
export { PRICE_LIST_SEIKATSU_NURSING };
