const PRICE_LIST_SEIKATSU_INJURY = {
  ID: "seikatsu-injury",
  PRICE_M: [
    {
      AGE: 0,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 1,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 2,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 3,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 4,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 5,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 6,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 7,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 8,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 9,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 10,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 11,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 12,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 13,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 14,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 15,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 16,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 17,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 18,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 19,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 20,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 21,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 22,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 23,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 24,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 25,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 26,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 27,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 28,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 29,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 30,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 31,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 32,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 33,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 34,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 35,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 36,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 37,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 38,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 39,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 40,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 41,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 42,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 43,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 44,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 45,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 46,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 47,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 48,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 49,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 50,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 51,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 52,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 53,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 54,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 55,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 56,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 57,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 58,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 59,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 60,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 61,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 62,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 63,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 64,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 65,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 66,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 67,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 68,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 69,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 70,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 71,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 72,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 73,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 74,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 75,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
  ],
  PRICE_F: [
    {
      AGE: 0,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 1,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 2,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 3,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 4,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 5,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 6,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 7,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 8,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 9,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 10,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 11,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 12,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 13,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 14,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 15,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 16,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 17,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 18,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 19,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 20,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 21,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 22,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 23,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 24,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 25,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 26,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 27,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 28,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 29,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 30,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 31,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 32,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 33,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 34,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 35,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 36,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 37,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 38,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 39,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 40,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 41,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 42,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 43,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 44,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 45,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 46,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 47,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 48,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 49,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 50,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 51,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 52,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 53,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 54,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 55,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 56,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 57,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 58,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 59,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 60,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 61,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 62,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 63,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 64,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 65,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 66,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 67,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 68,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 69,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 70,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 71,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 72,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 73,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 74,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
    {
      AGE: 75,
      1: { 1: { 1: 620, 2: 1240, 3: 1860 }, 2: { 1: 550, 2: 1100, 3: 1650 } },
      2: { 1: { 1: 2160, 2: 4320, 3: 6480 }, 2: { 1: 1930, 2: 3860, 3: 5790 } },
    },
  ],
};
export { PRICE_LIST_SEIKATSU_INJURY };
