const PRICE_LIST_SEIKATSU_CANCER = {
  ID: "seikatsu-cancer",
  PRICE_M: [
    { AGE: 0, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 1, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 2, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 3, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 4, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 5, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 6, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 7, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 8, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 9, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 10, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 11, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 12, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 13, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 14, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 15, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 16, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 17, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 18, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 19, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 20, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 21, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 22, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 23, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 24, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 25, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 26, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 27, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 28, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 29, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 30, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 31, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 32, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 33, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 34, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 35, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 36, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 37, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 38, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 39, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 40, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 41, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 42, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 43, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 44, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 45, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 46, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 47, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 48, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 49, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 50, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 51, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 52, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 53, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 54, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 55, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 56, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 57, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 58, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 59, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 60, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 61, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 62, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 63, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 64, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 65, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 66, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 67, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 68, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 69, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 70, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 71, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 72, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 73, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 74, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 75, 1: 2050, 2: 5560, 3: 4340, 4: 7850 },
  ],
  PRICE_F: [
    { AGE: 0, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 1, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 2, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 3, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 4, 1: 30, 2: 100, 3: 40, 4: 110 },
    { AGE: 5, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 6, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 7, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 8, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 9, 1: 30, 2: 110, 3: 50, 4: 130 },
    { AGE: 10, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 11, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 12, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 13, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 14, 1: 30, 2: 150, 3: 50, 4: 170 },
    { AGE: 15, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 16, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 17, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 18, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 19, 1: 30, 2: 120, 3: 60, 4: 150 },
    { AGE: 20, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 21, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 22, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 23, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 24, 1: 30, 2: 80, 3: 80, 4: 130 },
    { AGE: 25, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 26, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 27, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 28, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 29, 1: 50, 2: 150, 3: 110, 4: 210 },
    { AGE: 30, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 31, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 32, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 33, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 34, 1: 100, 2: 260, 3: 200, 4: 360 },
    { AGE: 35, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 36, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 37, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 38, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 39, 1: 160, 2: 390, 3: 340, 4: 570 },
    { AGE: 40, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 41, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 42, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 43, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 44, 1: 250, 2: 590, 3: 560, 4: 900 },
    { AGE: 45, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 46, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 47, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 48, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 49, 1: 370, 2: 840, 3: 810, 4: 1280 },
    { AGE: 50, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 51, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 52, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 53, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 54, 1: 460, 2: 1230, 3: 1080, 4: 1850 },
    { AGE: 55, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 56, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 57, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 58, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 59, 1: 680, 2: 1890, 3: 1540, 4: 2750 },
    { AGE: 60, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 61, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 62, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 63, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 64, 1: 1060, 2: 2820, 3: 2270, 4: 4030 },
    { AGE: 65, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 66, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 67, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 68, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 69, 1: 1440, 2: 3780, 3: 3020, 4: 5360 },
    { AGE: 70, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 71, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 72, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 73, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 74, 1: 1820, 2: 4730, 3: 3850, 4: 6760 },
    { AGE: 75, 1: 2050, 2: 5560, 3: 4340, 4: 7850 },
  ],
};
export { PRICE_LIST_SEIKATSU_CANCER };
