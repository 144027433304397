const PRICE_LIST_SHUSHIN_COURSE = {
  ID: "shushin-course",
  PRICE_M: [
    { AGE: 0, 1: 1032, 2: 1408 },
    { AGE: 1, 1: 1022, 2: 1408 },
    { AGE: 2, 1: 1012, 2: 1410 },
    { AGE: 3, 1: 1002, 2: 1411 },
    { AGE: 4, 1: 997, 2: 1418 },
    { AGE: 5, 1: 992, 2: 1425 },
    { AGE: 6, 1: 987, 2: 1433 },
    { AGE: 7, 1: 987, 2: 1447 },
    { AGE: 8, 1: 987, 2: 1462 },
    { AGE: 9, 1: 987, 2: 1478 },
    { AGE: 10, 1: 992, 2: 1498 },
    { AGE: 11, 1: 1007, 2: 1531 },
    { AGE: 12, 1: 1017, 2: 1558 },
    { AGE: 13, 1: 1032, 2: 1593 },
    { AGE: 14, 1: 1042, 2: 1623 },
    { AGE: 15, 1: 1057, 2: 1659 },
    { AGE: 16, 1: 1067, 2: 1691 },
    { AGE: 17, 1: 1077, 2: 1724 },
    { AGE: 18, 1: 1092, 2: 1765 },
    { AGE: 19, 1: 1102, 2: 1800 },
    { AGE: 20, 1: 1117, 2: 1843 },
    { AGE: 21, 1: 1147, 2: 1902 },
    { AGE: 22, 1: 1177, 2: 1962 },
    { AGE: 23, 1: 1212, 2: 2030 },
    { AGE: 24, 1: 1247, 2: 2098 },
    { AGE: 25, 1: 1277, 2: 2164 },
    { AGE: 26, 1: 1312, 2: 2237 },
    { AGE: 27, 1: 1352, 2: 2315 },
    { AGE: 28, 1: 1397, 2: 2403 },
    { AGE: 29, 1: 1437, 2: 2486 },
    { AGE: 30, 1: 1477, 2: 2572 },
    { AGE: 31, 1: 1522, 2: 2664 },
    { AGE: 32, 1: 1557, 2: 2751 },
    { AGE: 33, 1: 1602, 2: 2849 },
    { AGE: 34, 1: 1652, 2: 2956 },
    { AGE: 35, 1: 1702, 2: 3064 },
    { AGE: 36, 1: 1747, 2: 3171 },
    { AGE: 37, 1: 1802, 2: 3292 },
    { AGE: 38, 1: 1852, 2: 3411 },
    { AGE: 39, 1: 1907, 2: 3540 },
    { AGE: 40, 1: 1962, 2: 3672 },
    { AGE: 41, 1: 2037, 2: 3827 },
    { AGE: 42, 1: 2112, 2: 3988 },
    { AGE: 43, 1: 2182, 2: 4147 },
    { AGE: 44, 1: 2272, 2: 4330 },
    { AGE: 45, 1: 2352, 2: 4509 },
    { AGE: 46, 1: 2442, 2: 4702 },
    { AGE: 47, 1: 2527, 2: 4894 },
    { AGE: 48, 1: 2617, 2: 5095 },
    { AGE: 49, 1: 2722, 2: 5320 },
    { AGE: 50, 1: 2817, 2: 5536 },
    { AGE: 51, 1: 2942, 2: 5789 },
    { AGE: 52, 1: 3072, 2: 6050 },
    { AGE: 53, 1: 3212, 2: 6327 },
    { AGE: 54, 1: 3357, 2: 6612 },
    { AGE: 55, 1: 3507, 2: 6911 },
    { AGE: 56, 1: 3647, 2: 7199 },
    { AGE: 57, 1: 3802, 2: 7506 },
    { AGE: 58, 1: 3957, 2: 7817 },
    { AGE: 59, 1: 4117, 2: 8135 },
    { AGE: 60, 1: 4267, 2: 8448 },
    { AGE: 61, 1: 4412, 2: 8758 },
    { AGE: 62, 1: 4562, 2: 9075 },
    { AGE: 63, 1: 4712, 2: 9394 },
    { AGE: 64, 1: 4872, 2: 9725 },
    { AGE: 65, 1: 5037, 2: 10066 },
    { AGE: 66, 1: 5207, 2: 10405 },
    { AGE: 67, 1: 5392, 2: 10759 },
    { AGE: 68, 1: 5587, 2: 11120 },
    { AGE: 69, 1: 5792, 2: 11489 },
    { AGE: 70, 1: 6012, 2: 11872 },
    { AGE: 71, 1: 6247, 2: 12268 },
    { AGE: 72, 1: 6512, 2: 12695 },
    { AGE: 73, 1: 6797, 2: 13144 },
    { AGE: 74, 1: 7102, 2: 13617 },
    { AGE: 75, 1: 7437, 2: 14133 },
  ],
  PRICE_F: [
    { AGE: 0, 1: 1017, 2: 1392 },
    { AGE: 1, 1: 1017, 2: 1402 },
    { AGE: 2, 1: 1017, 2: 1413 },
    { AGE: 3, 1: 1017, 2: 1424 },
    { AGE: 4, 1: 1022, 2: 1441 },
    { AGE: 5, 1: 1027, 2: 1458 },
    { AGE: 6, 1: 1032, 2: 1476 },
    { AGE: 7, 1: 1042, 2: 1500 },
    { AGE: 8, 1: 1052, 2: 1524 },
    { AGE: 9, 1: 1062, 2: 1550 },
    { AGE: 10, 1: 1072, 2: 1576 },
    { AGE: 11, 1: 1097, 2: 1618 },
    { AGE: 12, 1: 1112, 2: 1650 },
    { AGE: 13, 1: 1137, 2: 1693 },
    { AGE: 14, 1: 1162, 2: 1738 },
    { AGE: 15, 1: 1187, 2: 1784 },
    { AGE: 16, 1: 1212, 2: 1830 },
    { AGE: 17, 1: 1237, 2: 1878 },
    { AGE: 18, 1: 1252, 2: 1917 },
    { AGE: 19, 1: 1277, 2: 1967 },
    { AGE: 20, 1: 1302, 2: 2019 },
    { AGE: 21, 1: 1327, 2: 2079 },
    { AGE: 22, 1: 1357, 2: 2147 },
    { AGE: 23, 1: 1382, 2: 2211 },
    { AGE: 24, 1: 1402, 2: 2274 },
    { AGE: 25, 1: 1422, 2: 2338 },
    { AGE: 26, 1: 1442, 2: 2404 },
    { AGE: 27, 1: 1457, 2: 2469 },
    { AGE: 28, 1: 1472, 2: 2536 },
    { AGE: 29, 1: 1487, 2: 2605 },
    { AGE: 30, 1: 1502, 2: 2678 },
    { AGE: 31, 1: 1522, 2: 2766 },
    { AGE: 32, 1: 1542, 2: 2855 },
    { AGE: 33, 1: 1557, 2: 2946 },
    { AGE: 34, 1: 1582, 2: 3049 },
    { AGE: 35, 1: 1602, 2: 3153 },
    { AGE: 36, 1: 1632, 2: 3272 },
    { AGE: 37, 1: 1662, 2: 3393 },
    { AGE: 38, 1: 1692, 2: 3521 },
    { AGE: 39, 1: 1722, 2: 3652 },
    { AGE: 40, 1: 1757, 2: 3796 },
    { AGE: 41, 1: 1802, 2: 3926 },
    { AGE: 42, 1: 1847, 2: 4054 },
    { AGE: 43, 1: 1897, 2: 4192 },
    { AGE: 44, 1: 1952, 2: 4334 },
    { AGE: 45, 1: 2007, 2: 4480 },
    { AGE: 46, 1: 2062, 2: 4625 },
    { AGE: 47, 1: 2127, 2: 4786 },
    { AGE: 48, 1: 2187, 2: 4940 },
    { AGE: 49, 1: 2257, 2: 5112 },
    { AGE: 50, 1: 2322, 2: 5278 },
    { AGE: 51, 1: 2412, 2: 5443 },
    { AGE: 52, 1: 2497, 2: 5601 },
    { AGE: 53, 1: 2592, 2: 5776 },
    { AGE: 54, 1: 2687, 2: 5955 },
    { AGE: 55, 1: 2787, 2: 6140 },
    { AGE: 56, 1: 2892, 2: 6327 },
    { AGE: 57, 1: 3002, 2: 6526 },
    { AGE: 58, 1: 3112, 2: 6727 },
    { AGE: 59, 1: 3232, 2: 6934 },
    { AGE: 60, 1: 3362, 2: 7160 },
    { AGE: 61, 1: 3492, 2: 7395 },
    { AGE: 62, 1: 3627, 2: 7639 },
    { AGE: 63, 1: 3772, 2: 7903 },
    { AGE: 64, 1: 3922, 2: 8170 },
    { AGE: 65, 1: 4082, 2: 8452 },
    { AGE: 66, 1: 4247, 2: 8744 },
    { AGE: 67, 1: 4422, 2: 9051 },
    { AGE: 68, 1: 4602, 2: 9377 },
    { AGE: 69, 1: 4797, 2: 9719 },
    { AGE: 70, 1: 5012, 2: 10089 },
    { AGE: 71, 1: 5232, 2: 10475 },
    { AGE: 72, 1: 5482, 2: 10901 },
    { AGE: 73, 1: 5742, 2: 11356 },
    { AGE: 74, 1: 6022, 2: 11829 },
    { AGE: 75, 1: 6322, 2: 12329 },
  ],
};
export { PRICE_LIST_SHUSHIN_COURSE };
