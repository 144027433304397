const PRICE_LIST_SEIKATSU_MEDICAL = {
  ID: "seikatsu-medical",
  PRICE_M: [
    {
      AGE: 0,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 1,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 2,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 3,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 4,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 5,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 6,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 7,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 8,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 9,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 10,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 11,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 12,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 13,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 14,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 15,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 16,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 17,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 18,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 19,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 20,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 21,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 22,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 23,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 24,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 25,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 26,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 27,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 28,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 29,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 30,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 31,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 32,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 33,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 34,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 35,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 36,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 37,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 38,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 39,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 40,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 41,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 42,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 43,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 44,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 45,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 46,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 47,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 48,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 49,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 50,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 51,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 52,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 53,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 54,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 55,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 56,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 57,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 58,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 59,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 60,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 61,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 62,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 63,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 64,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 65,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 66,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 67,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 68,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 69,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 70,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 71,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 72,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 73,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 74,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 75,
      1: 7390,
      2: 6420,
      3: 5150,
      4: 4180,
      5: 10320,
      6: 9350,
      7: 8080,
      8: 7110,
    },
  ],
  PRICE_F: [
    {
      AGE: 0,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 1,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 2,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 3,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 4,
      1: 390,
      2: 370,
      3: 370,
      4: 350,
      5: 450,
      6: 430,
      7: 430,
      8: 410,
    },
    {
      AGE: 5,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 6,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 7,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 8,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 9,
      1: 300,
      2: 280,
      3: 280,
      4: 260,
      5: 360,
      6: 340,
      7: 340,
      8: 320,
    },
    {
      AGE: 10,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 11,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 12,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 13,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 14,
      1: 280,
      2: 260,
      3: 260,
      4: 240,
      5: 340,
      6: 320,
      7: 320,
      8: 300,
    },
    {
      AGE: 15,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 16,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 17,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 18,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 19,
      1: 310,
      2: 290,
      3: 290,
      4: 270,
      5: 400,
      6: 380,
      7: 380,
      8: 360,
    },
    {
      AGE: 20,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 21,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 22,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 23,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 24,
      1: 440,
      2: 410,
      3: 410,
      4: 380,
      5: 630,
      6: 600,
      7: 600,
      8: 570,
    },
    {
      AGE: 25,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 26,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 27,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 28,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 29,
      1: 500,
      2: 460,
      3: 450,
      4: 410,
      5: 790,
      6: 750,
      7: 740,
      8: 700,
    },
    {
      AGE: 30,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 31,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 32,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 33,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 34,
      1: 540,
      2: 500,
      3: 470,
      4: 430,
      5: 880,
      6: 840,
      7: 810,
      8: 770,
    },
    {
      AGE: 35,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 36,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 37,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 38,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 39,
      1: 620,
      2: 570,
      3: 510,
      4: 460,
      5: 910,
      6: 860,
      7: 800,
      8: 750,
    },
    {
      AGE: 40,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 41,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 42,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 43,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 44,
      1: 730,
      2: 670,
      3: 580,
      4: 520,
      5: 1020,
      6: 960,
      7: 870,
      8: 810,
    },
    {
      AGE: 45,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 46,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 47,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 48,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 49,
      1: 1000,
      2: 920,
      3: 760,
      4: 680,
      5: 1370,
      6: 1290,
      7: 1130,
      8: 1050,
    },
    {
      AGE: 50,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 51,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 52,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 53,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 54,
      1: 1340,
      2: 1230,
      3: 990,
      4: 880,
      5: 1810,
      6: 1700,
      7: 1460,
      8: 1350,
    },
    {
      AGE: 55,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 56,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 57,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 58,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 59,
      1: 1970,
      2: 1800,
      3: 1400,
      4: 1230,
      5: 2610,
      6: 2440,
      7: 2040,
      8: 1870,
    },
    {
      AGE: 60,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 61,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 62,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 63,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 64,
      1: 2870,
      2: 2610,
      3: 2030,
      4: 1770,
      5: 3750,
      6: 3490,
      7: 2910,
      8: 2650,
    },
    {
      AGE: 65,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 66,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 67,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 68,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 69,
      1: 4060,
      2: 3650,
      3: 2820,
      4: 2410,
      5: 5330,
      6: 4920,
      7: 4090,
      8: 3680,
    },
    {
      AGE: 70,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 71,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 72,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 73,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 74,
      1: 5780,
      2: 5060,
      3: 4030,
      4: 3310,
      5: 7800,
      6: 7080,
      7: 6050,
      8: 5330,
    },
    {
      AGE: 75,
      1: 7390,
      2: 6420,
      3: 5150,
      4: 4180,
      5: 10320,
      6: 9350,
      7: 8080,
      8: 7110,
    },
  ],
};
export { PRICE_LIST_SEIKATSU_MEDICAL };
