const PRICE_LIST_SEIMEI_COURSE = {
  ID: "seimei-course",
  PRICE_M: [
    {
      AGE: 15,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 16,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 17,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 18,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 19,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 20,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 21,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 22,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 23,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 24,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 25,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 26,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 27,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 28,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 29,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 30,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 31,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 32,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 33,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 34,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 35,
      14: 6220,
      13: 5598,
      12: 4976,
      11: 4354,
      10: 3732,
      9: 3110,
      8: 2488,
      7: 1866,
      6: 1244,
      5: 870,
      4: 622,
      3: 373,
      2: 248,
      1: 124,
    },
    {
      AGE: 36,
      14: 6790,
      13: 6111,
      12: 5432,
      11: 4753,
      10: 4074,
      9: 3395,
      8: 2716,
      7: 2037,
      6: 1358,
      5: 950,
      4: 679,
      3: 407,
      2: 271,
      1: 135,
    },
    {
      AGE: 37,
      14: 6790,
      13: 6111,
      12: 5432,
      11: 4753,
      10: 4074,
      9: 3395,
      8: 2716,
      7: 2037,
      6: 1358,
      5: 950,
      4: 679,
      3: 407,
      2: 271,
      1: 135,
    },
    {
      AGE: 38,
      14: 6790,
      13: 6111,
      12: 5432,
      11: 4753,
      10: 4074,
      9: 3395,
      8: 2716,
      7: 2037,
      6: 1358,
      5: 950,
      4: 679,
      3: 407,
      2: 271,
      1: 135,
    },
    {
      AGE: 39,
      14: 6790,
      13: 6111,
      12: 5432,
      11: 4753,
      10: 4074,
      9: 3395,
      8: 2716,
      7: 2037,
      6: 1358,
      5: 950,
      4: 679,
      3: 407,
      2: 271,
      1: 135,
    },
    {
      AGE: 40,
      14: 6790,
      13: 6111,
      12: 5432,
      11: 4753,
      10: 4074,
      9: 3395,
      8: 2716,
      7: 2037,
      6: 1358,
      5: 950,
      4: 679,
      3: 407,
      2: 271,
      1: 135,
    },
    {
      AGE: 41,
      14: 7780,
      13: 7002,
      12: 6224,
      11: 5446,
      10: 4668,
      9: 3890,
      8: 3112,
      7: 2334,
      6: 1556,
      5: 1089,
      4: 778,
      3: 466,
      2: 311,
      1: 155,
    },
    {
      AGE: 42,
      14: 7780,
      13: 7002,
      12: 6224,
      11: 5446,
      10: 4668,
      9: 3890,
      8: 3112,
      7: 2334,
      6: 1556,
      5: 1089,
      4: 778,
      3: 466,
      2: 311,
      1: 155,
    },
    {
      AGE: 43,
      14: 7780,
      13: 7002,
      12: 6224,
      11: 5446,
      10: 4668,
      9: 3890,
      8: 3112,
      7: 2334,
      6: 1556,
      5: 1089,
      4: 778,
      3: 466,
      2: 311,
      1: 155,
    },
    {
      AGE: 44,
      14: 7780,
      13: 7002,
      12: 6224,
      11: 5446,
      10: 4668,
      9: 3890,
      8: 3112,
      7: 2334,
      6: 1556,
      5: 1089,
      4: 778,
      3: 466,
      2: 311,
      1: 155,
    },
    {
      AGE: 45,
      14: 7780,
      13: 7002,
      12: 6224,
      11: 5446,
      10: 4668,
      9: 3890,
      8: 3112,
      7: 2334,
      6: 1556,
      5: 1089,
      4: 778,
      3: 466,
      2: 311,
      1: 155,
    },
    {
      AGE: 46,
      14: 9370,
      13: 8433,
      12: 7496,
      11: 6559,
      10: 5622,
      9: 4685,
      8: 3748,
      7: 2811,
      6: 1874,
      5: 1311,
      4: 937,
      3: 562,
      2: 374,
      1: 187,
    },
    {
      AGE: 47,
      14: 9370,
      13: 8433,
      12: 7496,
      11: 6559,
      10: 5622,
      9: 4685,
      8: 3748,
      7: 2811,
      6: 1874,
      5: 1311,
      4: 937,
      3: 562,
      2: 374,
      1: 187,
    },
    {
      AGE: 48,
      14: 9370,
      13: 8433,
      12: 7496,
      11: 6559,
      10: 5622,
      9: 4685,
      8: 3748,
      7: 2811,
      6: 1874,
      5: 1311,
      4: 937,
      3: 562,
      2: 374,
      1: 187,
    },
    {
      AGE: 49,
      14: 9370,
      13: 8433,
      12: 7496,
      11: 6559,
      10: 5622,
      9: 4685,
      8: 3748,
      7: 2811,
      6: 1874,
      5: 1311,
      4: 937,
      3: 562,
      2: 374,
      1: 187,
    },
    {
      AGE: 50,
      14: 9370,
      13: 8433,
      12: 7496,
      11: 6559,
      10: 5622,
      9: 4685,
      8: 3748,
      7: 2811,
      6: 1874,
      5: 1311,
      4: 937,
      3: 562,
      2: 374,
      1: 187,
    },
    {
      AGE: 51,
      14: 11770,
      13: 10593,
      12: 9416,
      11: 8239,
      10: 7062,
      9: 5885,
      8: 4708,
      7: 3531,
      6: 2354,
      5: 1647,
      4: 1177,
      3: 706,
      2: 470,
      1: 235,
    },
    {
      AGE: 52,
      14: 11770,
      13: 10593,
      12: 9416,
      11: 8239,
      10: 7062,
      9: 5885,
      8: 4708,
      7: 3531,
      6: 2354,
      5: 1647,
      4: 1177,
      3: 706,
      2: 470,
      1: 235,
    },
    {
      AGE: 53,
      14: 11770,
      13: 10593,
      12: 9416,
      11: 8239,
      10: 7062,
      9: 5885,
      8: 4708,
      7: 3531,
      6: 2354,
      5: 1647,
      4: 1177,
      3: 706,
      2: 470,
      1: 235,
    },
    {
      AGE: 54,
      14: 11770,
      13: 10593,
      12: 9416,
      11: 8239,
      10: 7062,
      9: 5885,
      8: 4708,
      7: 3531,
      6: 2354,
      5: 1647,
      4: 1177,
      3: 706,
      2: 470,
      1: 235,
    },
    {
      AGE: 55,
      14: 11770,
      13: 10593,
      12: 9416,
      11: 8239,
      10: 7062,
      9: 5885,
      8: 4708,
      7: 3531,
      6: 2354,
      5: 1647,
      4: 1177,
      3: 706,
      2: 470,
      1: 235,
    },
    {
      AGE: 56,
      14: 15190,
      13: 13671,
      12: 12152,
      11: 10633,
      10: 9114,
      9: 7595,
      8: 6076,
      7: 4557,
      6: 3038,
      5: 2126,
      4: 1519,
      3: 911,
      2: 607,
      1: 303,
    },
    {
      AGE: 57,
      14: 15190,
      13: 13671,
      12: 12152,
      11: 10633,
      10: 9114,
      9: 7595,
      8: 6076,
      7: 4557,
      6: 3038,
      5: 2126,
      4: 1519,
      3: 911,
      2: 607,
      1: 303,
    },
    {
      AGE: 58,
      14: 15190,
      13: 13671,
      12: 12152,
      11: 10633,
      10: 9114,
      9: 7595,
      8: 6076,
      7: 4557,
      6: 3038,
      5: 2126,
      4: 1519,
      3: 911,
      2: 607,
      1: 303,
    },
    {
      AGE: 59,
      14: 15190,
      13: 13671,
      12: 12152,
      11: 10633,
      10: 9114,
      9: 7595,
      8: 6076,
      7: 4557,
      6: 3038,
      5: 2126,
      4: 1519,
      3: 911,
      2: 607,
      1: 303,
    },
    {
      AGE: 60,
      14: 15190,
      13: 13671,
      12: 12152,
      11: 10633,
      10: 9114,
      9: 7595,
      8: 6076,
      7: 4557,
      6: 3038,
      5: 2126,
      4: 1519,
      3: 911,
      2: 607,
      1: 303,
    },
    {
      AGE: 61,
      14: 21100,
      13: 18990,
      12: 16880,
      11: 14770,
      10: 12660,
      9: 10550,
      8: 8440,
      7: 6330,
      6: 4220,
      5: 2954,
      4: 2110,
      3: 1266,
      2: 844,
      1: 422,
    },
    {
      AGE: 62,
      14: 21100,
      13: 18990,
      12: 16880,
      11: 14770,
      10: 12660,
      9: 10550,
      8: 8440,
      7: 6330,
      6: 4220,
      5: 2954,
      4: 2110,
      3: 1266,
      2: 844,
      1: 422,
    },
    {
      AGE: 63,
      14: 21100,
      13: 18990,
      12: 16880,
      11: 14770,
      10: 12660,
      9: 10550,
      8: 8440,
      7: 6330,
      6: 4220,
      5: 2954,
      4: 2110,
      3: 1266,
      2: 844,
      1: 422,
    },
    {
      AGE: 64,
      14: 21100,
      13: 18990,
      12: 16880,
      11: 14770,
      10: 12660,
      9: 10550,
      8: 8440,
      7: 6330,
      6: 4220,
      5: 2954,
      4: 2110,
      3: 1266,
      2: 844,
      1: 422,
    },
    {
      AGE: 65,
      14: 21100,
      13: 18990,
      12: 16880,
      11: 14770,
      10: 12660,
      9: 10550,
      8: 8440,
      7: 6330,
      6: 4220,
      5: 2954,
      4: 2110,
      3: 1266,
      2: 844,
      1: 422,
    },
    {
      AGE: 66,
      14: 29290,
      13: 26361,
      12: 23432,
      11: 20503,
      10: 17574,
      9: 14645,
      8: 11716,
      7: 8787,
      6: 5858,
      5: 4100,
      4: 2929,
      3: 1757,
      2: 1171,
      1: 585,
    },
    {
      AGE: 67,
      14: 29290,
      13: 26361,
      12: 23432,
      11: 20503,
      10: 17574,
      9: 14645,
      8: 11716,
      7: 8787,
      6: 5858,
      5: 4100,
      4: 2929,
      3: 1757,
      2: 1171,
      1: 585,
    },
    {
      AGE: 68,
      14: 29290,
      13: 26361,
      12: 23432,
      11: 20503,
      10: 17574,
      9: 14645,
      8: 11716,
      7: 8787,
      6: 5858,
      5: 4100,
      4: 2929,
      3: 1757,
      2: 1171,
      1: 585,
    },
    {
      AGE: 69,
      14: 29290,
      13: 26361,
      12: 23432,
      11: 20503,
      10: 17574,
      9: 14645,
      8: 11716,
      7: 8787,
      6: 5858,
      5: 4100,
      4: 2929,
      3: 1757,
      2: 1171,
      1: 585,
    },
    {
      AGE: 70,
      14: 29290,
      13: 26361,
      12: 23432,
      11: 20503,
      10: 17574,
      9: 14645,
      8: 11716,
      7: 8787,
      6: 5858,
      5: 4100,
      4: 2929,
      3: 1757,
      2: 1171,
      1: 585,
    },
    {
      AGE: 71,
      14: 37090,
      13: 33381,
      12: 29672,
      11: 25963,
      10: 22254,
      9: 18545,
      8: 14836,
      7: 11127,
      6: 7418,
      5: 5192,
      4: 3709,
      3: 2225,
      2: 1483,
      1: 741,
    },
    {
      AGE: 72,
      14: 40600,
      13: 36540,
      12: 32480,
      11: 28420,
      10: 24360,
      9: 20300,
      8: 16240,
      7: 12180,
      6: 8120,
      5: 5684,
      4: 4060,
      3: 2436,
      2: 1624,
      1: 812,
    },
    {
      AGE: 73,
      14: 44680,
      13: 40212,
      12: 35744,
      11: 31276,
      10: 26808,
      9: 22340,
      8: 17872,
      7: 13404,
      6: 8936,
      5: 6255,
      4: 4468,
      3: 2680,
      2: 1787,
      1: 893,
    },
    {
      AGE: 74,
      14: 49390,
      13: 44451,
      12: 39512,
      11: 34573,
      10: 29634,
      9: 24695,
      8: 19756,
      7: 14817,
      6: 9878,
      5: 6914,
      4: 4939,
      3: 2963,
      2: 1975,
      1: 987,
    },
    {
      AGE: 75,
      14: 54970,
      13: 49473,
      12: 43976,
      11: 38479,
      10: 32982,
      9: 27485,
      8: 21988,
      7: 16491,
      6: 10994,
      5: 7695,
      4: 5497,
      3: 3298,
      2: 2198,
      1: 1099,
    },
  ],
  PRICE_F: [
    {
      AGE: 15,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 16,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 17,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 18,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 19,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 20,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 21,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 22,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 23,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 24,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 25,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 26,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 27,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 28,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 29,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 30,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 31,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 32,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 33,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 34,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 35,
      14: 5440,
      13: 4896,
      12: 4352,
      11: 3808,
      10: 3264,
      9: 2720,
      8: 2176,
      7: 1632,
      6: 1088,
      5: 761,
      4: 544,
      3: 326,
      2: 217,
      1: 108,
    },
    {
      AGE: 36,
      14: 6370,
      13: 5733,
      12: 5096,
      11: 4459,
      10: 3822,
      9: 3185,
      8: 2548,
      7: 1911,
      6: 1274,
      5: 891,
      4: 637,
      3: 382,
      2: 254,
      1: 127,
    },
    {
      AGE: 37,
      14: 6370,
      13: 5733,
      12: 5096,
      11: 4459,
      10: 3822,
      9: 3185,
      8: 2548,
      7: 1911,
      6: 1274,
      5: 891,
      4: 637,
      3: 382,
      2: 254,
      1: 127,
    },
    {
      AGE: 38,
      14: 6370,
      13: 5733,
      12: 5096,
      11: 4459,
      10: 3822,
      9: 3185,
      8: 2548,
      7: 1911,
      6: 1274,
      5: 891,
      4: 637,
      3: 382,
      2: 254,
      1: 127,
    },
    {
      AGE: 39,
      14: 6370,
      13: 5733,
      12: 5096,
      11: 4459,
      10: 3822,
      9: 3185,
      8: 2548,
      7: 1911,
      6: 1274,
      5: 891,
      4: 637,
      3: 382,
      2: 254,
      1: 127,
    },
    {
      AGE: 40,
      14: 6370,
      13: 5733,
      12: 5096,
      11: 4459,
      10: 3822,
      9: 3185,
      8: 2548,
      7: 1911,
      6: 1274,
      5: 891,
      4: 637,
      3: 382,
      2: 254,
      1: 127,
    },
    {
      AGE: 41,
      14: 6880,
      13: 6192,
      12: 5504,
      11: 4816,
      10: 4128,
      9: 3440,
      8: 2752,
      7: 2064,
      6: 1376,
      5: 963,
      4: 688,
      3: 412,
      2: 275,
      1: 137,
    },
    {
      AGE: 42,
      14: 6880,
      13: 6192,
      12: 5504,
      11: 4816,
      10: 4128,
      9: 3440,
      8: 2752,
      7: 2064,
      6: 1376,
      5: 963,
      4: 688,
      3: 412,
      2: 275,
      1: 137,
    },
    {
      AGE: 43,
      14: 6880,
      13: 6192,
      12: 5504,
      11: 4816,
      10: 4128,
      9: 3440,
      8: 2752,
      7: 2064,
      6: 1376,
      5: 963,
      4: 688,
      3: 412,
      2: 275,
      1: 137,
    },
    {
      AGE: 44,
      14: 6880,
      13: 6192,
      12: 5504,
      11: 4816,
      10: 4128,
      9: 3440,
      8: 2752,
      7: 2064,
      6: 1376,
      5: 963,
      4: 688,
      3: 412,
      2: 275,
      1: 137,
    },
    {
      AGE: 45,
      14: 6880,
      13: 6192,
      12: 5504,
      11: 4816,
      10: 4128,
      9: 3440,
      8: 2752,
      7: 2064,
      6: 1376,
      5: 963,
      4: 688,
      3: 412,
      2: 275,
      1: 137,
    },
    {
      AGE: 46,
      14: 8050,
      13: 7245,
      12: 6440,
      11: 5635,
      10: 4830,
      9: 4025,
      8: 3220,
      7: 2415,
      6: 1610,
      5: 1127,
      4: 805,
      3: 483,
      2: 322,
      1: 161,
    },
    {
      AGE: 47,
      14: 8050,
      13: 7245,
      12: 6440,
      11: 5635,
      10: 4830,
      9: 4025,
      8: 3220,
      7: 2415,
      6: 1610,
      5: 1127,
      4: 805,
      3: 483,
      2: 322,
      1: 161,
    },
    {
      AGE: 48,
      14: 8050,
      13: 7245,
      12: 6440,
      11: 5635,
      10: 4830,
      9: 4025,
      8: 3220,
      7: 2415,
      6: 1610,
      5: 1127,
      4: 805,
      3: 483,
      2: 322,
      1: 161,
    },
    {
      AGE: 49,
      14: 8050,
      13: 7245,
      12: 6440,
      11: 5635,
      10: 4830,
      9: 4025,
      8: 3220,
      7: 2415,
      6: 1610,
      5: 1127,
      4: 805,
      3: 483,
      2: 322,
      1: 161,
    },
    {
      AGE: 50,
      14: 8050,
      13: 7245,
      12: 6440,
      11: 5635,
      10: 4830,
      9: 4025,
      8: 3220,
      7: 2415,
      6: 1610,
      5: 1127,
      4: 805,
      3: 483,
      2: 322,
      1: 161,
    },
    {
      AGE: 51,
      14: 9460,
      13: 8514,
      12: 7568,
      11: 6622,
      10: 5676,
      9: 4730,
      8: 3784,
      7: 2838,
      6: 1892,
      5: 1324,
      4: 946,
      3: 567,
      2: 378,
      1: 189,
    },
    {
      AGE: 52,
      14: 9460,
      13: 8514,
      12: 7568,
      11: 6622,
      10: 5676,
      9: 4730,
      8: 3784,
      7: 2838,
      6: 1892,
      5: 1324,
      4: 946,
      3: 567,
      2: 378,
      1: 189,
    },
    {
      AGE: 53,
      14: 9460,
      13: 8514,
      12: 7568,
      11: 6622,
      10: 5676,
      9: 4730,
      8: 3784,
      7: 2838,
      6: 1892,
      5: 1324,
      4: 946,
      3: 567,
      2: 378,
      1: 189,
    },
    {
      AGE: 54,
      14: 9460,
      13: 8514,
      12: 7568,
      11: 6622,
      10: 5676,
      9: 4730,
      8: 3784,
      7: 2838,
      6: 1892,
      5: 1324,
      4: 946,
      3: 567,
      2: 378,
      1: 189,
    },
    {
      AGE: 55,
      14: 9460,
      13: 8514,
      12: 7568,
      11: 6622,
      10: 5676,
      9: 4730,
      8: 3784,
      7: 2838,
      6: 1892,
      5: 1324,
      4: 946,
      3: 567,
      2: 378,
      1: 189,
    },
    {
      AGE: 56,
      14: 10900,
      13: 9810,
      12: 8720,
      11: 7630,
      10: 6540,
      9: 5450,
      8: 4360,
      7: 3270,
      6: 2180,
      5: 1526,
      4: 1090,
      3: 654,
      2: 436,
      1: 218,
    },
    {
      AGE: 57,
      14: 10900,
      13: 9810,
      12: 8720,
      11: 7630,
      10: 6540,
      9: 5450,
      8: 4360,
      7: 3270,
      6: 2180,
      5: 1526,
      4: 1090,
      3: 654,
      2: 436,
      1: 218,
    },
    {
      AGE: 58,
      14: 10900,
      13: 9810,
      12: 8720,
      11: 7630,
      10: 6540,
      9: 5450,
      8: 4360,
      7: 3270,
      6: 2180,
      5: 1526,
      4: 1090,
      3: 654,
      2: 436,
      1: 218,
    },
    {
      AGE: 59,
      14: 10900,
      13: 9810,
      12: 8720,
      11: 7630,
      10: 6540,
      9: 5450,
      8: 4360,
      7: 3270,
      6: 2180,
      5: 1526,
      4: 1090,
      3: 654,
      2: 436,
      1: 218,
    },
    {
      AGE: 60,
      14: 10900,
      13: 9810,
      12: 8720,
      11: 7630,
      10: 6540,
      9: 5450,
      8: 4360,
      7: 3270,
      6: 2180,
      5: 1526,
      4: 1090,
      3: 654,
      2: 436,
      1: 218,
    },
    {
      AGE: 61,
      14: 13120,
      13: 11808,
      12: 10496,
      11: 9184,
      10: 7872,
      9: 6560,
      8: 5248,
      7: 3936,
      6: 2624,
      5: 1836,
      4: 1312,
      3: 787,
      2: 524,
      1: 262,
    },
    {
      AGE: 62,
      14: 13120,
      13: 11808,
      12: 10496,
      11: 9184,
      10: 7872,
      9: 6560,
      8: 5248,
      7: 3936,
      6: 2624,
      5: 1836,
      4: 1312,
      3: 787,
      2: 524,
      1: 262,
    },
    {
      AGE: 63,
      14: 13120,
      13: 11808,
      12: 10496,
      11: 9184,
      10: 7872,
      9: 6560,
      8: 5248,
      7: 3936,
      6: 2624,
      5: 1836,
      4: 1312,
      3: 787,
      2: 524,
      1: 262,
    },
    {
      AGE: 64,
      14: 13120,
      13: 11808,
      12: 10496,
      11: 9184,
      10: 7872,
      9: 6560,
      8: 5248,
      7: 3936,
      6: 2624,
      5: 1836,
      4: 1312,
      3: 787,
      2: 524,
      1: 262,
    },
    {
      AGE: 65,
      14: 13120,
      13: 11808,
      12: 10496,
      11: 9184,
      10: 7872,
      9: 6560,
      8: 5248,
      7: 3936,
      6: 2624,
      5: 1836,
      4: 1312,
      3: 787,
      2: 524,
      1: 262,
    },
    {
      AGE: 66,
      14: 16270,
      13: 14643,
      12: 13016,
      11: 11389,
      10: 9762,
      9: 8135,
      8: 6508,
      7: 4881,
      6: 3254,
      5: 2277,
      4: 1627,
      3: 976,
      2: 650,
      1: 325,
    },
    {
      AGE: 67,
      14: 16270,
      13: 14643,
      12: 13016,
      11: 11389,
      10: 9762,
      9: 8135,
      8: 6508,
      7: 4881,
      6: 3254,
      5: 2277,
      4: 1627,
      3: 976,
      2: 650,
      1: 325,
    },
    {
      AGE: 68,
      14: 16270,
      13: 14643,
      12: 13016,
      11: 11389,
      10: 9762,
      9: 8135,
      8: 6508,
      7: 4881,
      6: 3254,
      5: 2277,
      4: 1627,
      3: 976,
      2: 650,
      1: 325,
    },
    {
      AGE: 69,
      14: 16270,
      13: 14643,
      12: 13016,
      11: 11389,
      10: 9762,
      9: 8135,
      8: 6508,
      7: 4881,
      6: 3254,
      5: 2277,
      4: 1627,
      3: 976,
      2: 650,
      1: 325,
    },
    {
      AGE: 70,
      14: 16270,
      13: 14643,
      12: 13016,
      11: 11389,
      10: 9762,
      9: 8135,
      8: 6508,
      7: 4881,
      6: 3254,
      5: 2277,
      4: 1627,
      3: 976,
      2: 650,
      1: 325,
    },
    {
      AGE: 71,
      14: 20260,
      13: 18234,
      12: 16208,
      11: 14182,
      10: 12156,
      9: 10130,
      8: 8104,
      7: 6078,
      6: 4052,
      5: 2836,
      4: 2026,
      3: 1215,
      2: 810,
      1: 405,
    },
    {
      AGE: 72,
      14: 22090,
      13: 19881,
      12: 17672,
      11: 15463,
      10: 13254,
      9: 11045,
      8: 8836,
      7: 6627,
      6: 4418,
      5: 3092,
      4: 2209,
      3: 1325,
      2: 883,
      1: 441,
    },
    {
      AGE: 73,
      14: 24250,
      13: 21825,
      12: 19400,
      11: 16975,
      10: 14550,
      9: 12125,
      8: 9700,
      7: 7275,
      6: 4850,
      5: 3395,
      4: 2425,
      3: 1455,
      2: 970,
      1: 485,
    },
    {
      AGE: 74,
      14: 26650,
      13: 23985,
      12: 21320,
      11: 18655,
      10: 15990,
      9: 13325,
      8: 10660,
      7: 7995,
      6: 5330,
      5: 3731,
      4: 2665,
      3: 1599,
      2: 1066,
      1: 533,
    },
    {
      AGE: 75,
      14: 29230,
      13: 26307,
      12: 23384,
      11: 20461,
      10: 17538,
      9: 14615,
      8: 11692,
      7: 8769,
      6: 5846,
      5: 4092,
      4: 2923,
      3: 1753,
      2: 1169,
      1: 584,
    },
  ],
  PRICE_C: [
    { AGE: 3, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 4, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 5, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 6, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 7, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 8, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 9, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 10, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 11, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 12, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 13, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 14, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 15, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 16, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 17, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 18, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 19, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 20, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 21, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 22, 1: 70, 2: 140, 3: 210, 4: 280 },
  ],
};
export { PRICE_LIST_SEIMEI_COURSE };
